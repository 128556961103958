import React, { useState, useEffect } from 'react';

import { client as apollo } from 'cccisd-apollo';
import { OutcomesDataEntry } from 'cccisd-laravel-assignment';

import treatmentGroupsQuery from './treatmentGroups.graphql';

var Fortress = window.cccisd.fortress;

function OutcomesData() {
    const [treatmentGroups, setTreatmentGroups] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        initializeData();
    }, []);

    async function initializeData() {
        const treatmentGroupsList = await getTreatmentGroups();

        await setTreatmentGroups(treatmentGroupsList.groups.treatmentGroupList);

        setIsLoading(false);
    }

    async function getTreatmentGroups() {
        let groupId = Fortress.user.acting.group_id;
        const res = await apollo.query({
            query: treatmentGroupsQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId,
            },
        });

        return res.data;
    }

    function formatTreatmentGroups(groups) {
        return groups.map(
            ({
                group: { groupId, label },
                childRoles: {
                    metricspawn: {
                        pawn: { pawnId, pawnHash },
                    },
                },
            }) => {
                return {
                    groupId,
                    label,
                    pawnId,
                    pawnHash,
                };
            }
        );
    }

    return (
        !isLoading && (
            <OutcomesDataEntry
                groups={formatTreatmentGroups(treatmentGroups)}
                description="Outcome assessments enable programs to explore the degree to which the services, content, and activities delivered to participants are having the desired impact. To explore this data further, go to the Report Center to view corresponding reports."
                tableColumns={[
                    {
                        name: 'fields.participantId',
                        label: 'Participant',
                        sort: true,
                        filter: true,
                    },
                    { name: 'fields.firstName', label: 'First Name', sort: true, filter: true },
                    { name: 'fields.lastName', label: 'Last Name', sort: true, filter: true },
                    {
                        name: 'fields.caregiverId',
                        label: 'Caregiver',
                        sort: true,
                        filter: true,
                    },
                ]}
                tableFields={['participantId', 'firstName', 'lastName', 'caregiverId']}
                modalTitleField="participantId"
            />
        )
    );
}

export default OutcomesData;
