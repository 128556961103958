import React, { useState, useEffect } from 'react';

import { client as apollo } from 'cccisd-apollo';
import { SessionDataEntry } from 'cccisd-laravel-assignment';

import treatmentGroupsQuery from './treatmentGroups.graphql';

var Fortress = window.cccisd.fortress;

function SessionData() {
    const [treatmentGroups, setTreatmentGroups] = useState([]);
    const [facilitatorData, setFacilitatorData] = useState();

    const facilitatorList = facilitatorData && formatFacilitatorList(facilitatorData);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        initializeData();
    }, []);

    async function initializeData() {
        const treatmentGroupsList = await getTreatmentGroups();

        await setTreatmentGroups(treatmentGroupsList.groups.treatmentGroupList);
        await setFacilitatorData(treatmentGroupsList.roles.providerList);

        setIsLoading(false);
    }

    async function getTreatmentGroups() {
        let groupId = Fortress.user.acting.group_id;
        const res = await apollo.query({
            query: treatmentGroupsQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId,
            },
        });

        return res.data;
    }

    function formatTreatmentGroups(groups) {
        return groups.map(
            ({
                group: { groupId, label },
                childRoles: {
                    metricspawn: {
                        pawn: { pawnId, pawnHash },
                    },
                    participantList,
                },
            }) => {
                return {
                    groupId,
                    label,
                    pawnId,
                    pawnHash,
                    flowProps: getFlowProps(participantList),
                };
            }
        );
    }

    function formatFacilitatorList(list) {
        return list.map(item => {
            return {
                id: item.pawn.pawnId,
                name: item.facilitatorName,
            };
        });
    }

    function formatParticipantList(list) {
        return list.map(item => {
            return {
                pawnId: item.pawn.pawnId,
                pawnHash: item.pawn.pawnHash,
                name: item.fields.participantId,
            };
        });
    }

    function getFlowProps(participantList) {
        return {
            variables: {
                facilitatorList,
            },
            userLists: {
                participantList: formatParticipantList(participantList),
            },
        };
    }

    return (
        !isLoading && (
            <SessionDataEntry
                groups={formatTreatmentGroups(treatmentGroups)}
                description="Session Data supports data entry and collection at the session and client-level. Users may enter contact data (attendance), adherence and fidelity data for each session. To explore this data further, go to the Report Center to view corresponding reports."
            />
        )
    );
}

export default SessionData;
