import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'cccisd-axios';
import { client as apollo } from 'cccisd-apollo';
import { NotFound } from 'cccisd-laravel-boilerplate';
import Loader from 'cccisd-loader';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import deploymentQuery from './deployment.graphql';
const Fortress = window.cccisd && window.cccisd.fortress;
const Boilerplate = window.cccisd && window.cccisd.boilerplate;

DeploymentEvaluator.propTypes = {
    /** The layout to use for the 404 page */
    errorLayout: PropTypes.func,
    /* From redux-router */
    match: PropTypes.object.isRequired,
    /* deployment hash */
    deploymentHash: PropTypes.string,
    respondentHash: PropTypes.string,
};

function DeploymentEvaluator({
    errorLayout = null,
    match: {
        params: { deploymentHash, respondentHash },
    },
}) {
    const [isLoading, setIsLoading] = useState(true);
    const [show404, setShow404] = useState(false);

    const [deploymentHandle, setDeploymentHandle] = useState();
    const [participant, setParticipant] = useState();

    useEffect(() => {
        evaluateDeployment();
    }, []);

    const logoutUser = async () => {
        if (Fortress.auth()) {
            await axios.get(Boilerplate.route('api.nexus.logout'));
            window.location.reload();
        }
    };

    /**
     * Get the deployment handle and pawn data, which is needed to route
     * to the deployment for this pawn. It also retrieves pawn data
     * which is used to pre populate data in the flow
     */
    const evaluateDeployment = async () => {
        if (!deploymentHash || !respondentHash) {
            setShow404(true);
            setIsLoading(false);
            return;
        }
        try {
            const result = await apollo.query({
                query: deploymentQuery,
                fetchPolicy: 'network-only',
                variables: {
                    deploymentHash,
                    respondentHash,
                },
            });
            if (!result.data.flows.deployment.hash) {
                setShow404(true);
                setIsLoading(false);
                return;
            }
            await logoutUser();
            await setDeploymentHandle(result.data.flows.deployment.deploymentHandle);
            await setParticipant(result.data.roles.participant);
        } catch (error) {
            setShow404(true);
        }
        await setIsLoading(false);
    };

    if (isLoading) {
        return <Loader loading />;
    }
    if (show404) {
        if (errorLayout) {
            let ErrorLayout = errorLayout;
            return (
                <ErrorLayout>
                    <NotFound />
                </ErrorLayout>
            );
        }
        return <NotFound />;
    }

    return (
        <div style={{ marginTop: '2rem' }}>
            <h1>{`Surveys for ${participant.fields.participantId}`}</h1>
            <DeploymentPlayer
                deploymentHandle={deploymentHandle}
                pawnId={participant.pawn.pawnId}
                pawnHash={participant.pawn.pawnHash}
                flowProps={{
                    hidePlayButton: true,
                    isPrintMode: false,
                }}
                disableLayout
            />
        </div>
    );
}

export default DeploymentEvaluator;
